import { useNavigate } from '@tanstack/react-router';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';
import { flushSync } from 'react-dom';
import { asyncStorage, store } from './store';

export type SessionToken = { auth_key: string; full_name: string; username: string; email: string };

export const sessionTokenAtom = atomWithStorage<Partial<SessionToken>>('admin:sessionToken', {}, asyncStorage, {
  getOnInit: true,
});
export const redirectToAtom = atom('');

export const useLoggedIn = () => {
  const account = useAtomValue(sessionTokenAtom);
  return Boolean(account.auth_key);
};

export const useLogout = () => {
  const navigate = useNavigate();
  const setAccount = useSetAtom(sessionTokenAtom);
  return () => {
    flushSync(() => {
      setAccount(() => RESET);
      navigate({ to: '/login', search: { redirect: '/' }, replace: true });
    });
  };
};

export async function getAuthorizationHeader() {
  const account = await store.get(sessionTokenAtom);
  return account.auth_key ? { Authorization: `Bearer ${account.auth_key}` } : undefined;
}
