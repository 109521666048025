/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridColDef, ValueOptions } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { SearchState, useDataGridData } from '@/hooks/useDataGridData';
import { useValuesData } from '@/hooks/useValuesData';
import { dateToPresentFormat } from '@/lib/utils';
import { LogbookActivityAPI } from '@/types/logbookActivity';
import { StudentValueOption } from '@/types/values';

const logbookActivityAtom = atom<SearchState>({
  form: {
    expand: 'logbook,procedure,hospital,completionStatus,student,rotation',
  },
  sort: { key: 'id', asc: false },
  page: 0,
  pageSize: 10,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

const LogbookActivityView = () => {
  const {
    result: { refetch, data },
    searchState,
  } = useDataGridData<LogbookActivityAPI>({
    baseUrl: '/assistant/admin/logbook-activities',
    atom: logbookActivityAtom,
    dto: true,
    dtoDateFields: ['date_start', 'date_end'],
  });
  // const {
  //   result: { data: valueLogbookData },
  // } = useValuesData({
  //   baseUrl: '/assistant/values/logbooks ',
  // });
  const {
    result: { data: valueHospitalData },
  } = useValuesData({
    baseUrl: '/assistant/values/hospitals ',
  });
  const {
    result: { data: valueProcedureData },
  } = useValuesData({
    baseUrl: '/assistant/values/procedures ',
  });
  const {
    result: { data: valueActivityCompletionStatusData },
  } = useValuesData({
    baseUrl: '/assistant/values/activity-completion-status',
  });
  const {
    result: { data: valueStudentIdData },
  } = useValuesData({
    baseUrl: '/assistant/values/student-id',
  });
  const {
    result: { data: valueStudentNameData },
  } = useValuesData({
    baseUrl: '/assistant/values/student-name',
  });
  const {
    result: { data: valueSpecialtyData },
  } = useValuesData({
    baseUrl: '/assistant/values/specialties ',
  });
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
      field: 'logbook.studentId_id',
      headerName: 'Student ID',
      type: 'singleSelect',
      valueOptions: valueStudentIdData.items as StudentValueOption[],
      renderCell: (params) => {
        return (
          <div>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
            {(
              valueStudentIdData?.items?.find((item: any) => {
                return item.value === params.row?.student?.ID;
              }) as {
                label: string;
              }
            )?.label || ''}
          </div>
        );
      },
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field: 'logbook.studentName_id',
      headerName: 'Student Name',
      type: 'singleSelect',
      valueOptions: valueStudentNameData.items as StudentValueOption[],
      renderCell: (params) => {
        return (
          <div>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
            {(
              valueStudentNameData?.items?.find((item: any) => item.value === params.row?.student?.ID) as {
                label: string;
              }
            )?.label || ''}
          </div>
        );
      },
      editable: false,
      sortable: false,
      width: 150,
    },
    {
      field: 'rotation.start_date',
      headerName: 'Rotation Start Date',
      type: 'date',
      editable: false,
      renderCell: (params) => dateToPresentFormat(new Date(params.row.rotation?.start_date)),
      width: 150,
    },
    {
      field: 'rotation.end_date',
      headerName: 'Rotation End Date',
      valueFormatter: (params) => dateToPresentFormat(new Date(params)),
      type: 'date',
      editable: false,
      renderCell: (params) => dateToPresentFormat(new Date(params.row.rotation?.end_date)),
      width: 150,
    },
    {
      field: 'rotation.specialty_id',
      headerName: 'Specialty',
      type: 'singleSelect',
      valueOptions: valueSpecialtyData.items as ValueOptions[],
      renderCell: (params) => {
        return (
          <div>
            {(
              valueSpecialtyData?.items?.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (item: any) => item.value === params.row?.rotation?.specialty_id
              ) as {
                label: string;
              }
            )?.label || ''}
          </div>
        );
      },
      editable: false,
      sortable: false,
      width: 150,
    },
    // {
    //   field: 'logbook_id',
    //   headerName: 'Logbook',
    //   width: 300,
    //   type: 'singleSelect',
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   getOptionValue: (value: any) => value?.value,
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   getOptionLabel: (value: any) => value?.label,
    //   valueOptions: valueLogbookData.items as ValueOptions[],
    //   renderEditCell: (params) => {
    //     if (!params.row.isNew) {
    //       return (
    //         <AutocompleteEditInputCell
    //           params={params}
    //           valueOptions={valueLogbookData.items as LogbookValueOption[]}
    //           // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //           getOptionLabel={(option: any) => {
    //             return (
    //               // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //               (valueLogbookData.items.find((item: any) => item.value === option) as LogbookValueOption)?.label ??
    //               option?.label ??
    //               ''
    //             );
    //           }}
    //           freeSolo={false}
    //           multiple={false}
    //         />
    //       );
    //     } else {
    //       return <SelectLogbookDialog params={params} />;
    //     }
    //   },
    //   preProcessEditCellProps: (params) => required(params),
    // },
    {
      field: 'date_start',
      headerName: 'Start Date',
      width: 120,
      type: 'date',
      valueFormatter: (params) => dateToPresentFormat(new Date(params)),
    },
    {
      field: 'date_end',
      headerName: 'End Date',
      width: 120,
      type: 'date',
      valueFormatter: (params) => dateToPresentFormat(new Date(params)),
    },
    {
      field: 'hospital_code',
      headerName: 'Hospital',
      width: 150,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.code,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueHospitalData.items as ValueOptions[],
    },
    {
      field: 'ward',
      headerName: 'Ward',
      width: 100,
    },
    {
      field: 'procedure_id',
      headerName: 'Procedure',
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.id,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueProcedureData.items as ValueOptions[],
      renderCell: (params) => {
        return <div>{params.row.procedure?.name ?? ''}</div>;
      },
      width: 200,
    },
    {
      field: 'other_procedure',
      headerName: 'Optional Procedure',
      width: 150,
    },
    {
      field: 'completion_status_code',
      headerName: 'Completion Status',
      width: 150,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.code,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.name,
      valueOptions: valueActivityCompletionStatusData.items as ValueOptions[],
    },
    {
      field: 'tutor_name',
      headerName: 'Tutor/Trainer Name',
      width: 150,
    },
    {
      field: 'tutor_position',
      headerName: 'Tutor/Trainer Position',
      width: 150,
    },
    {
      field: 'remark',
      headerName: 'Remark',
      width: 150,
    },
  ];
  return (
    <FullFeaturedCrudGrid
      columns={columns}
      initialRows={data?.items}
      refetch={refetch}
      dataAtom={logbookActivityAtom}
      baseUrl="/assistant/admin/logbook-activities"
      dto
      dtoDateFields={['date_start', 'date_end', 'rotation.start_date', 'rotation.end_date']}
      paginationModelsProps={{
        totalElements: searchState?.totalElements,
        pageSize: searchState?.pageSize,
        page: searchState?.page,
      }}
      customSortFieldMapper={{
        procedure_id: 'procedure.name',
        hospital_id: 'hospital.name',
        completion_status_code: 'completionStatus.name',
      }}
      cellEditable={false}
      deletable={false}
      insertable={false}
      exportTableKey="logbook-activity"
    />
  );
};

export default LogbookActivityView;
