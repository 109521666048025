import { GridColDef, ValueOptions } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { AutocompleteEditInputCell } from '@/components/form/AutocompleteEditInputCell';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { SearchState, useDataGridData } from '@/hooks/useDataGridData';
import { useValuesData } from '@/hooks/useValuesData';
import { datetimeToPresentFormat } from '@/lib/utils';
import { LogbookAPI } from '@/types/logbook';
import { AdminRolesValueOption } from '@/types/values';
import ResetPasswordAction from './ResetPasswordAction';

const adminUserAtom = atom<SearchState>({
  form: {
    expand: 'role_names',
  },
  sort: { key: 'id', asc: true },
  page: 0,
  pageSize: 10,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

function AdminUserView() {
  const {
    result: { refetch, data },
    searchState,
  } = useDataGridData<LogbookAPI>({
    baseUrl: '/assistant/admin/admin-users',
    atom: adminUserAtom,
    dto: true,
    dtoDatetimeFields: ['created_at'],
  });
  const {
    result: { data: valueAdminRolesData },
  } = useValuesData({
    baseUrl: '/assistant/values/admin-roles ',
  });

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'username', headerName: 'Username', width: 200, editable: true },
    { field: 'full_name', headerName: 'Full name', width: 200, editable: true },
    { field: 'email', headerName: 'Email', width: 200, editable: true },
    {
      field: 'role_names',
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.name,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.description,
      valueOptions: valueAdminRolesData.items as ValueOptions[],
      headerName: 'Role',
      width: 400,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.roles
              ?.map((role: { name: string; description: string }) => String(role.name).toUpperCase())
              .join(', ')}
          </div>
        );
      },
      renderEditCell: (params) => {
        return (
          <AutocompleteEditInputCell
            params={params}
            valueOptions={valueAdminRolesData.items as AdminRolesValueOption[]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getOptionLabel={(option: any) => {
              return (
                (
                  valueAdminRolesData.items.find(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (item: any) => item.name === option
                  ) as AdminRolesValueOption
                )?.description ??
                option?.description ??
                ''
              );
            }}
            valueParser={(newValue, multiple) => {
              if (multiple) return newValue.map((v: AdminRolesValueOption) => v?.name ?? v);
              return newValue.name;
            }}
            getDefaultValue={(params, multiple) => {
              if (params.value) return params.value;
              if (params.row.isNew) {
                return multiple ? [] : '';
              }
            }}
            multiple={true}
          />
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: [
        { value: 10, label: 'Active' },
        { value: 0, label: 'Inactive' },
      ] as ValueOptions[],
      editable: true,
      width: 100,
    },
    {
      field: 'created_at',
      type: 'dateTime',
      valueFormatter: (params) =>
        params ? datetimeToPresentFormat(new Date(params)) : datetimeToPresentFormat(new Date()),
      headerName: 'Created At',
      width: 200,
    },
  ];

  return (
    <FullFeaturedCrudGrid
      columns={columns}
      initialRows={data?.items}
      refetch={refetch}
      dataAtom={adminUserAtom}
      baseUrl="/assistant/admin/admin-users"
      dto
      dtoDatetimeFields={['created_at']}
      paginationModelsProps={{
        totalElements: searchState?.totalElements,
        pageSize: searchState?.pageSize,
        page: searchState?.page,
      }}
      startActionFC={ResetPasswordAction}
    />
  );
}

export default AdminUserView;
