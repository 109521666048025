import { createStore } from 'jotai';
import localForage from 'localforage';

export const store = createStore();

export const localForageInstance = localForage.createInstance({
  storeName: 'med-assistance-internship-admin-portal',
  version: 1,
});

export interface AsyncStorage<Value> {
  getItem: (key: string, initialValue: Value) => PromiseLike<Value>;
  setItem: (key: string, newValue: Value) => PromiseLike<void>;
  removeItem: (key: string) => PromiseLike<void>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const asyncStorage: AsyncStorage<any> = {
  async getItem(key, initialValue) {
    const value = await localForageInstance.getItem(key);
    return value ?? initialValue;
  },
  async setItem(key, value) {
    await localForageInstance.setItem(key, value);
  },
  async removeItem(key) {
    await localForageInstance.removeItem(key);
  },
};
