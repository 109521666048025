import { useQuery } from '@tanstack/react-query';
import { env } from '@/env';
import { getAuthorizationHeader } from '@/jotai/account';
import { dtoHandlerWithoutMeta, fetchResponseHandler } from '@/lib/fetch-utils';
import { FlattenKeys } from '@/types/helper';

export function useValuesData<
  TRowData,
  DK extends FlattenKeys<TRowData> = FlattenKeys<TRowData>,
  BK extends FlattenKeys<TRowData> = FlattenKeys<TRowData>,
>({
  baseUrl,
  dto = false,
  dtoDateFields = [],
  dtoBooleanFields = [],
}: {
  baseUrl: string;
  dto?: boolean;
  dtoDateFields?: DK[];
  dtoBooleanFields?: BK[];
}) {
  const result = useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const data = await fetch(`${env.VITE_API_BASE_URL}${baseUrl}`, {
        headers: await getAuthorizationHeader(),
      })
        .then(fetchResponseHandler<{ items: TRowData[] }>())
        .then((data) => {
          if (dto) {
            // console.log('dto', { dtoDateFields, dtoBooleanFields });
            return dtoHandlerWithoutMeta<{ items: TRowData[] }, TRowData, DK, BK>(
              data,
              dtoDateFields,
              dtoBooleanFields
            );
          }
          return data;
        });
      const items = data.items;
      return { items };
    },
    initialData: { items: [] },
  });

  return {
    result,
  };
}
