import { QueryClient } from '@tanstack/react-query';
import { env } from './env';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: env.MODE === 'development',
    },
  },
});
