import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Button, Dialog, DialogActions, DialogTitle, Portal } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { env } from '@/env';
import { getAuthorizationHeader } from '@/jotai/account';
import { useOpenSnackbar } from '@/jotai/snackbar';
import { fetchResponseHandler } from '@/lib/fetch-utils';

function ResetPasswordAction({ id }: { id: string | number }) {
  const snackbarOpen = useOpenSnackbar();
  const handleResetPasswordClick = React.useCallback(async () => {
    fetch(`${env.VITE_API_BASE_URL}/assistant/admin/admin-user/reset-password?id=${id}`, {
      method: 'POST',
      headers: { ...(await getAuthorizationHeader()) },
    })
      .then(fetchResponseHandler<{ success: boolean }>())
      .then(({ success }) => {
        if (success) {
          snackbarOpen('Password reset successfully');
        } else {
          throw new Error('Password reset failed');
        }
      })
      .finally(() => setOpen(false))
      .catch((error) => snackbarOpen(error.message));
  }, [snackbarOpen, id]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
      <GridActionsCellItem
        icon={<RestartAltIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleOpen}
        color="inherit"
      />
      <Portal>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirm to reset password for user:{id}?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleResetPasswordClick}>
              Reset Password
            </Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </React.Fragment>
  );
}

export default ResetPasswordAction;
