import { Link } from '@tanstack/react-router';

export function DefaultLayout({ children }: { children: React.ReactNode }) {
  return (
    <>
      <header className="flex h-16 items-center bg-white px-[clamp(0.25rem,1vw,1rem)] text-primary-foreground">
        <Link
          to="/"
          className="rounded-md outline-none ring-offset-background hover:underline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
        >
          <img src="/med_logo.png" alt="CUHK Med logo" />
        </Link>
      </header>
      {children}
      <footer className="bg-slate-700 px-2 pb-4 pt-8 text-primary-foreground">
        <p className="text-left text-sm">© Copyright 2024 by FPO, Faculty of Medicine, CUHK</p>
      </footer>
    </>
  );
}
