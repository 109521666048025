import { GridColDef } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { SearchState, useDataGridData } from '@/hooks/useDataGridData';
import { ProcedureApi } from '@/types/procedure';

const procedureAtom = atom<SearchState>({
  form: {},
  sort: { key: 'id', asc: true },
  page: 0,
  pageSize: 10,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

function ProcedureView() {
  const {
    result: { refetch, data },
    searchState,
  } = useDataGridData<ProcedureApi>({
    baseUrl: '/assistant/admin/procedures',
    atom: procedureAtom,
    dto: true,
    dtoBooleanFields: ['active', 'compulsory'],
  });

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'name', headerName: 'Name', editable: true, width: 600 },
    { field: 'min_number', headerName: 'Minimal Requirement', type: 'number', editable: true, width: 156 },
    { field: 'compulsory', headerName: 'Compulsory', type: 'boolean', editable: true, width: 150 },
    { field: 'active', headerName: 'Active', type: 'boolean', editable: true, width: 150 },
  ];

  return (
    <FullFeaturedCrudGrid
      columns={columns}
      initialRows={data?.items}
      refetch={refetch}
      dataAtom={procedureAtom}
      baseUrl="/assistant/admin/procedures"
      paginationModelsProps={{
        totalElements: searchState?.totalElements,
        pageSize: searchState?.pageSize,
        page: searchState?.page,
      }}
      deletable={false}
      dto={true}
      dtoBooleanFields={['active', 'compulsory']}
      exportable={false}
    />
  );
}

export { ProcedureView };
