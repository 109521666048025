import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { HospitalView } from '@/routeComponents/code/HospitalView';

const HospitalPage = () => {
  return (
    <main className="container mx-auto py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Hospital</h1>
      <HospitalView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/hospital')({
  component: HospitalPage,
});
