import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { ProcedureView } from '@/routeComponents/procedure/ProcedureView';

const ProcedurePage = () => {
  return (
    <main className="container mx-auto py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Procedure</h1>
      <ProcedureView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/procedure')({
  component: ProcedurePage,
});
