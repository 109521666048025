import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogTitle, Portal } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

type ConfirmDeleteDialogProps = {
  rowId: string | number;
  handleDeleteClick: () => void;
};

const ConfirmDeleteDialog = ({ rowId, handleDeleteClick }: ConfirmDeleteDialogProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirmClick = () => {
    handleDeleteClick();
    handleClose();
  };

  return (
    <React.Fragment>
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        className="textPrimary"
        onClick={handleOpen}
        color="inherit"
      />
      <Portal>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirm delete ID:{rowId}?</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmClick}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </React.Fragment>
  );
};

export default ConfirmDeleteDialog;
