import { Button } from '@mui/material';
import { TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import YiiZodForm from '@/components/form/YiiZodForm';
import { env } from '@/env';
import { useOpenSnackbar } from '@/jotai/snackbar';
import { fetchResponseHandler } from '@/lib/fetch-utils';

const forgetPasswordSchema = z.object({
  email: z.string().min(1),
});

function ForgetPasswordForm() {
  const snackbarOpen = useOpenSnackbar();
  const handleForgetPassword = async (values: { email: string }) => {
    return fetch(`${env.VITE_API_BASE_URL}/assistant/account/request-password-reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    })
      .then(fetchResponseHandler<{ success: boolean }>())
      .then((data) => {
        snackbarOpen(
          data ? 'Password reset request sent. Check your mailbox.' : 'Something went wrong. Please try again.'
        );
      });
  };

  return (
    <YiiZodForm zodSchema={forgetPasswordSchema} onSubmit={handleForgetPassword}>
      <div className="flex flex-col gap-4">
        <TextFieldElement type="email" name="email" label="Email" fullWidth />
        <Button variant="contained" type="submit">
          Reset Password Request
        </Button>
      </div>
    </YiiZodForm>
  );
}

export { ForgetPasswordForm };
