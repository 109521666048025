import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertDateFields(formObj: Record<string, unknown>, dateFields: string[]) {
  dateFields.forEach((field) => {
    if (formObj[field] instanceof Date) {
      formObj[field] = dateToDbFormat(formObj[field] as Date);
    } else if (typeof formObj[field] === 'string' || typeof formObj[field] === 'number') {
      formObj[field] = dateToDbFormat(new Date(formObj[field] as string | number));
    }
  });
  return formObj;
}

export function dateToDbFormat(date: Date) {
  if (!(date instanceof Date)) return date;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function dateToPresentFormat(date: Date) {
  if (!(date instanceof Date)) return date;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export function datetimeToDbFormat(date: Date) {
  if (!(date instanceof Date)) return date;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

export function datetimeToPresentFormat(date: Date) {
  if (!(date instanceof Date)) return date;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function required(params: { props: { value: string | number | Date | null } } | Record<any, any>) {
  return {
    ...params.props,
    error: !params.props.value,
  };
}
