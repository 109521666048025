import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { ForgetPasswordForm } from '@/routeComponents/login/ForgetPassworForm';

function ForgetPasswordPage() {
  return (
    <main className="container mx-auto max-w-lg py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Forget Password</h1>
      <ForgetPasswordForm />
    </main>
  );
}

export const Route = createFileRoute('/_unauthenticated/forget-password')({
  component: ForgetPasswordPage,
});
