import { useCallback } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext, ValueOptions } from '@mui/x-data-grid';

interface AutocompleteEditInputCellProps {
  params: GridRenderEditCellParams;
  valueOptions: ValueOptions[];
  freeSolo?: boolean;
  multiple?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueParser?: (option: any, multiple: boolean) => string | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionLabel?: (option: any) => string;
  getDefaultValue?: (params: GridRenderEditCellParams, multiple: boolean) => string | string[];
}

const getDefaultOptionLabel = () => (option: ValueOptions) => {
  if (typeof option === 'string' || typeof option === 'number') {
    return option.toString(); // Convert number or return string directly
  } else if ('label' in option && typeof option.label === 'string') {
    return option.label; // Safely return the label if it exists and is a string
  } else {
    return ''; // Default return value for cases where label is not available
  }
};

/**
 * Since GridStateColDef is not exported, we pass valueOptions and getOptionLabel as props instead for correct type.
 * @param props AutocompleteEditInputCellProps
 * @returns Autocomplete component
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultValueParser =
  () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any, multiple = false) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (multiple) return value.map((v: any) => v?.value ?? v);
    return value.value;
  };

export function AutocompleteEditInputCell(props: AutocompleteEditInputCellProps) {
  const {
    params,
    valueOptions,
    getOptionLabel = getDefaultOptionLabel(),
    valueParser = defaultValueParser(),
    getDefaultValue,
    freeSolo,
    multiple = false,
    ...restProps
  } = props;
  const apiRef = useGridApiContext();
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
      event.preventDefault();
      event.stopPropagation();
      apiRef.current.setEditCellValue({
        id: params.id,
        field: params.field,
        value: valueParser(newValue, multiple),
      });
    },
    [params.id, params.field, apiRef, valueParser, multiple]
  );

  const getValue = useCallback(() => {
    if (params.value) return params.value;

    if (multiple) return [];

    return null;
  }, [params.value, multiple]);

  return (
    <Autocomplete
      value={getDefaultValue ? getDefaultValue(params, multiple) : getValue()}
      onChange={handleChange}
      onInputChange={(event, value) => freeSolo && !multiple && event && handleChange(event, value)}
      fullWidth
      multiple={multiple}
      options={valueOptions ?? []}
      getOptionLabel={getOptionLabel}
      freeSolo={freeSolo}
      autoHighlight
      renderInput={(inputParams) => (
        <TextField
          {...inputParams}
          error={params.error}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 0,
            },
          }}
        />
      )}
      {...restProps}
    />
  );
}
