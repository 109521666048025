import { Pagination, TablePagination } from '@mui/material';
import { GridSlotProps, useGridApiContext } from '@mui/x-data-grid';

export interface FullFeaturedCrudGridPaginationProps {
  rowEditingId?: string;
  rowCreatingId?: string;
}

const FullFeaturedCrudGridPagination = (props: FullFeaturedCrudGridPaginationProps & GridSlotProps['pagination']) => {
  const apiRef = useGridApiContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_event: any, value: any) => {
    if (props.rowCreatingId || props.rowEditingId)
      return alert('Please save or cancel the current edit before changing page');
    apiRef.current.setPage(value - 1); // DataGrid page index starts from 0
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    apiRef.current.setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    apiRef.current.setPageSize(+event.target.value);
  };

  return (
    <div className="flex items-center justify-end">
      <TablePagination
        color="primary"
        count={apiRef.current.state.pagination.rowCount}
        page={apiRef.current.state.pagination.paginationModel.page} // DataGrid page index starts from 0
        rowsPerPage={apiRef.current.state.pagination.paginationModel.pageSize}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        slotProps={{
          actions: {
            nextButton: {
              className: '!hidden',
            },
            previousButton: {
              className: '!hidden',
            },
          },
        }}
      />
      <Pagination
        count={Math.ceil(
          apiRef.current.state.pagination.rowCount / apiRef.current.state.pagination.paginationModel.pageSize
        )}
        page={apiRef.current.state.pagination.paginationModel.page + 1} // DataGrid page index starts from 0
        onChange={handleChange}
        showFirstButton
        showLastButton
      />
    </div>
  );
};

export default FullFeaturedCrudGridPagination;
