import { Button } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useSetAtom } from 'jotai';
import { flushSync } from 'react-dom';
import { PasswordElement, TextFieldElement } from 'react-hook-form-mui';
import { z } from 'zod';
import YiiZodForm from '@/components/form/YiiZodForm';
import { env } from '@/env';
import { sessionTokenAtom, type SessionToken } from '@/jotai/account';
import { fetchResponseHandler } from '@/lib/fetch-utils';

const loginSchema = z.object({
  username: z.string().trim().min(1, { message: 'Required.' }),
  password: z.string().min(1, { message: 'Required.' }),
});

function LoginForm({ redirectTo }: { redirectTo: string }) {
  const navigate = useNavigate({ from: '/login' });
  const setAccount = useSetAtom(sessionTokenAtom);
  return (
    <div>
      <YiiZodForm
        zodSchema={loginSchema}
        onSubmit={async (values) => {
          return fetch(`${env.VITE_API_BASE_URL}/assistant/account/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
          })
            .then(fetchResponseHandler<SessionToken>())
            .then((data) => {
              flushSync(() => {
                setAccount(data);
                navigate({ to: redirectTo, search: true, replace: true });
              });
            });
        }}
      >
        <div className="flex flex-col gap-4">
          <TextFieldElement name="username" label="Username" fullWidth />
          <PasswordElement name="password" label="Password" fullWidth />
          <Button variant="contained" type="submit">
            Login
          </Button>
          <Button variant="text" href="/forget-password">
            Forget Password
          </Button>
        </div>
      </YiiZodForm>
    </div>
  );
}

export { LoginForm };
