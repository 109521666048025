import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { env } from '@/env';
import { fetchResponseHandler } from '@/lib/fetch-utils';
import { cn } from '@/lib/utils';
import ResetPasswordForm from '@/routeComponents/login/ResetPasswordForm';

const checkTokenValidity = async (token: string) => {
  try {
    const response = await fetch(`${env.VITE_API_BASE_URL}/assistant/account/token?token=${token}`).then(
      fetchResponseHandler<{ email: string }>()
    );
    return response;
  } catch (e) {
    console.log(e); // catch error
  }
};

const ResetPasswordPage = () => {
  const checkTokenValidityResponse = Route.useLoaderData();
  return (
    <main className="container mx-auto max-w-lg py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Reset Password</h1>
      {checkTokenValidityResponse ? <ResetPasswordForm /> : 'Invalid Token'}
    </main>
  );
};

export const Route = createFileRoute('/_unauthenticated/reset-password/$token')({
  loader: async ({ params: { token } }) => await checkTokenValidity(token),
  component: ResetPasswordPage,
});
