import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { DefaultLayout } from '@/components/layout/DefaultLayout';

function UnauthenticatedLayout() {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
}
export const Route = createFileRoute('/_unauthenticated')({
  component: UnauthenticatedLayout,
  beforeLoad: ({ context }) => {
    if (context.account?.auth_key) {
      throw redirect({ to: '/', replace: true });
    }
  },
});
