import React, { Suspense } from 'react';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { Toaster } from 'sonner';
import { DefaultLayout } from '@/components/layout/DefaultLayout';
import { LayoutSnackbar } from '@/components/layout/LayoutSnackbar';
import { env } from '@/env';
import { SessionToken } from '@/jotai/account';

const TanStackRouterDevtools =
  env.MODE === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

export const Route = createRootRouteWithContext<{
  account: Partial<SessionToken>;
}>()({
  component: () => (
    <>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
        <LayoutSnackbar />
      </Suspense>
    </>
  ),
  notFoundComponent: () => (
    <DefaultLayout>
      <h1>404 Not Found</h1>
      <Toaster />
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </DefaultLayout>
  ),
});
