import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import {
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridSlotProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { nanoid } from 'nanoid';
import { env } from '@/env';
import { getAuthorizationHeader } from '@/jotai/account';
import BatchActionPanel from './BatchActionPanel';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  rowCreatingId: string | undefined;
  rowEditingId: string | undefined;
  insertable?: boolean;
  exportable?: boolean;
  filterable?: boolean;
  exportTableKey?: string;
}

function EditToolbar(props: EditToolbarProps & GridSlotProps['toolbar']) {
  const {
    setRows,
    setRowModesModel,
    rowCreatingId,
    rowEditingId,
    insertable = true,
    exportable = true,
    filterable = true,
    exportTableKey,
  } = props;

  const handleClick = () => {
    const id = nanoid();
    setRows((oldRows) => [...oldRows, { id: id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const handleServerExport = React.useCallback(async () => {
    let filename = 'export.xlsx';
    fetch(`${env.VITE_API_BASE_URL}/assistant/admin/${exportTableKey}/export`, {
      headers: {
        ...(await getAuthorizationHeader()),
      },
    })
      .then((response) => {
        const header = response.headers.get('Content-Disposition');
        const parts = header!.split(';');
        filename = parts[1].split('=')[1].replace(/['"]+/g, '');
        return response.blob();
      })
      .then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        const a = document.createElement('a');
        a.href = objectURL;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.log(error));
  }, [exportTableKey]);

  if (rowEditingId || rowCreatingId) return <GridToolbarContainer></GridToolbarContainer>;

  return (
    <GridToolbarContainer>
      {insertable && !rowCreatingId && !rowEditingId && (
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>
      )}
      <GridToolbarColumnsButton />
      {filterable && <GridToolbarFilterButton />}
      {/* {exportable && <GridToolbarExport excelOptions={{ allColumns: true }} csvOptions={{ allColumns: true }} />} */}{' '}
      {/* Disable client side export */}
      {exportable && exportTableKey ? (
        <Button startIcon={<Save />} size={'small'} onClick={handleServerExport}>
          Export All
        </Button>
      ) : null}
      <div className="flex-1"></div>
      <BatchActionPanel />
    </GridToolbarContainer>
  );
}

export default EditToolbar;
