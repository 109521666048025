import React from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';

const CrudGridRowContext = React.createContext<{
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
}>({ rowSelectionModel: [], setRowSelectionModel: () => {} });

export const CrudGridRowContextProvider = CrudGridRowContext.Provider;
export function UseCrudGridRowContext() {
  return React.useContext(CrudGridRowContext);
}
