import { ValueOptions, type GridColDef } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { useDataGridData, type SearchState } from '@/hooks/useDataGridData';
import { useValuesData } from '@/hooks/useValuesData';
import { datetimeToPresentFormat, dateToPresentFormat } from '@/lib/utils';
import { RotationAPI } from '@/types/rotation';

const rotationAtom = atom<SearchState>({
  form: {},
  sort: { key: 'id', asc: true },
  page: 0,
  pageSize: 10,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

function RotationView() {
  const {
    result: { refetch, data },
    searchState,
  } = useDataGridData<RotationAPI>({
    baseUrl: '/assistant/admin/rotations',
    atom: rotationAtom,
    dto: true,
    dtoDateFields: ['start_date', 'end_date', 'submit_date'],
    dtoDatetimeFields: ['created_at', 'updated_at'],
  });
  const {
    result: { data: valueSpecialtyData },
  } = useValuesData({
    baseUrl: '/assistant/values/specialties ',
  });
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'start_date',
      headerName: 'Start Date',
      type: 'date',
      valueFormatter: (params) => dateToPresentFormat(new Date(params)),
      editable: true,
      width: 120,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      type: 'date',
      valueFormatter: (params) => dateToPresentFormat(new Date(params)),
      editable: true,
      width: 120,
    },
    {
      field: 'submit_date',
      headerName: 'Submission Deadline',
      type: 'date',
      valueFormatter: (params) => dateToPresentFormat(new Date(params)),
      editable: true,
      width: 150,
    },
    {
      field: 'specialty_id',
      headerName: 'Specialty',
      width: 150,
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.value,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value?.label,
      valueOptions: valueSpecialtyData.items as ValueOptions[],
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: [
        { value: 10, label: 'Active' },
        { value: 1, label: 'Inactive' },
      ],
      editable: true,
      width: 150,
    },
    {
      field: 'created_at',
      type: 'dateTime',
      valueFormatter: (params) =>
        params ? datetimeToPresentFormat(new Date(params)) : datetimeToPresentFormat(new Date()),
      headerName: 'Created At',
      width: 200,
    },
    {
      field: 'updated_at',
      type: 'dateTime',
      valueFormatter: (params) =>
        params ? datetimeToPresentFormat(new Date(params)) : datetimeToPresentFormat(new Date()),
      headerName: 'Updated At',
      width: 200,
    },
  ];
  return (
    <FullFeaturedCrudGrid
      columns={columns}
      initialRows={data?.items}
      refetch={refetch}
      dataAtom={rotationAtom}
      baseUrl="/assistant/admin/rotations"
      dto
      dtoDateFields={['start_date', 'end_date', 'submit_date']}
      dtoDatetimeFields={['created_at', 'updated_at']}
      paginationModelsProps={{
        totalElements: searchState?.totalElements,
        pageSize: searchState?.pageSize,
        page: searchState?.page,
      }}
      deletable={false}
      exportTableKey="rotation"
    />
  );
}

export { RotationView };
