import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import AdminUserView from '@/routeComponents/adminUser/AdminUserView';

const AdminUserPage = () => {
  return (
    <main className="container mx-auto py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Admin User</h1>
      <AdminUserView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/admin-user')({
  component: AdminUserPage,
});
