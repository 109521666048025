import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { ActivityCompletionStatusView } from '@/routeComponents/code/ActivityCompletionStatusView';

const EvaluationGradePage = () => {
  return (
    <main className="container mx-auto py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Activity Completion Status</h1>
      <ActivityCompletionStatusView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/activity-completion-status')({
  component: EvaluationGradePage,
});
