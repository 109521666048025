import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { RotationView } from '@/routeComponents/rotation/RotationView';

const RotationPage = () => {
  return (
    <main className="mx-auto p-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Rotation</h1>
      <RotationView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/rotation')({
  component: RotationPage,
});
