import { Snackbar } from '@mui/material';
import { useCloseSnackbar, useSnackbar } from '@/jotai/snackbar';

export const LayoutSnackbar = () => {
  const { snackbar } = useSnackbar();
  const closeSnackbar = useCloseSnackbar();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: snackbar.payload.vertical,
        horizontal: snackbar.payload.horizontal,
      }}
      message={snackbar.message}
      open={snackbar.payload.open}
      autoHideDuration={6000}
      onClose={closeSnackbar}
    />
  );
};
