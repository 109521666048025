import { atom, useAtom, useSetAtom } from 'jotai';

export type SnackbarAtom = {
  open: boolean;
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
};

export type SnackbarAtomAction = {
  message: string;
  payload: SnackbarAtom;
};
export type SnackbarAtomType = SnackbarAtom & SnackbarAtomAction;

const SnackbarAtom = atom<SnackbarAtom>({
  open: false,
  vertical: 'bottom',
  horizontal: 'center',
});

const SnackbarAtomAction = atom<SnackbarAtomAction>({
  message: '',
  payload: SnackbarAtom.init,
});

export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useAtom<SnackbarAtomAction>(SnackbarAtomAction);
  return { snackbar, setSnackbar };
};

export const useOpenSnackbar = () => {
  const setSnackbar = useSetAtom(SnackbarAtomAction);
  return (message: string) =>
    setSnackbar({
      message,
      payload: {
        open: true,
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
};

export const useCloseSnackbar = () => {
  const setSnackbar = useSetAtom(SnackbarAtomAction);
  return () =>
    setSnackbar({
      message: '',
      payload: {
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
};
