import { Suspense } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { Provider as JotaiProvider, useAtomValue } from 'jotai';
import { env } from './env';
import { sessionTokenAtom } from './jotai/account';
import { store } from './jotai/store';
import { queryClient } from './react-query';
import { routeTree } from './routeTree.gen';
import './styles/globals.css';

// Create a new router instance
const router = createRouter({
  basepath: env.BASE_URL,
  routeTree,
  context: { account: {} },
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function Providers() {
  const account = useAtomValue(sessionTokenAtom);
  return <RouterProvider context={{ account }} router={router} />;
}

function App() {
  return (
    <JotaiProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <Suspense>
          <Providers />
        </Suspense>
      </QueryClientProvider>
    </JotaiProvider>
  );
}

export default App;
