import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { FormContainer, useFormContext } from 'react-hook-form-mui';
import { z } from 'zod';
import { FetchError } from '@/lib/fetch-utils';

/**
 * @file YiiZodForm.tsx
 * @brief Form component for YiiZod
 * @description Wire the yii2 error handling with zod form and mui-hook-form
 */

const FormContextExtraction = React.forwardRef<UseFormReturn, React.PropsWithChildren>((props, ref) => {
  const context = useFormContext();
  React.useImperativeHandle(ref, () => ({
    ...context,
  }));
  return <div>{props.children}</div>;
});
FormContextExtraction.displayName = 'FormContextExtraction';

export interface YiiZodFormProps<TZodSchema extends z.Schema, TFieldValues extends FieldValues = FieldValues>
  extends Omit<React.ComponentPropsWithoutRef<typeof FormContainer<TFieldValues>>, 'resolver' | 'onSuccess'> {
  zodSchema: TZodSchema;
  onSubmit: (values: TFieldValues) => Promise<unknown>;
}

function YiiZodForm<TZodSchema extends z.Schema, TFieldValues extends FieldValues = z.infer<TZodSchema>>({
  zodSchema,
  onSubmit,
  children,
  ...props
}: YiiZodFormProps<TZodSchema, TFieldValues>) {
  const formContextRef = React.useRef<UseFormReturn>(null);
  const wrappedOnSubmit = React.useCallback(
    (values: TFieldValues) => {
      return onSubmit(values).catch((error: unknown) => {
        if (error instanceof FetchError && error.status === 422 && error.data) {
          // peek the error and handle 422
          Object.entries(error.data).forEach(([key, value]) => {
            formContextRef?.current?.setError(key, {
              type: 'server',
              message: value?.[0] || 'Invalid value',
            });
          });
        }
        throw error;
      });
    },
    [onSubmit]
  );
  return (
    <FormContainer onSuccess={wrappedOnSubmit} resolver={zodResolver(zodSchema)} {...props}>
      <FormContextExtraction ref={formContextRef}>{children}</FormContextExtraction>
    </FormContainer>
  );
}

export default YiiZodForm;
