import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { LogbookView } from '@/routeComponents/logbook/LogbookView';

const LogbookPage = () => {
  return (
    <main className="mx-auto p-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Logbook</h1>
      <LogbookView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/logbook')({
  component: LogbookPage,
});
