import { Button } from '@mui/material';
import { GridDeleteIcon, GridRowId } from '@mui/x-data-grid';
import { UseCrudGridRowContext } from './CrudGridRowContext';

async function batchDeleteAction(ids: GridRowId[]) {
  //todo:: Batch delete action, this is not recommended to delete all at once
  console.log(ids);
}

const BatchActionPanel = () => {
  const { rowSelectionModel } = UseCrudGridRowContext();
  if (rowSelectionModel.length === 0) {
    return null;
  }
  return (
    <div className="inline-flex self-center transition-all">
      <Button onClick={() => batchDeleteAction(rowSelectionModel)} startIcon={<GridDeleteIcon />}>
        Delete All
      </Button>
    </div>
  );
};

export default BatchActionPanel;
