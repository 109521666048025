import { createFileRoute } from '@tanstack/react-router';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { SpecialtyView } from '@/routeComponents/specialty/SpecialtyView';

const SpecialtyPage = () => {
  return (
    <main className="container mx-auto py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Specialty</h1>
      <SpecialtyView />
    </main>
  );
};

export const Route = createFileRoute('/_authenticated/specialty')({
  component: SpecialtyPage,
});
