import { Paper, Typography } from '@mui/material';
import { createFileRoute } from '@tanstack/react-router';
import { useAtomValue } from 'jotai';
import { sessionTokenAtom } from '@/jotai/account';

function Dashboard() {
  const { full_name } = useAtomValue(sessionTokenAtom);
  return (
    <main className="container space-y-8 py-8">
      <Paper variant="outlined" className="p-4">
        <Typography gutterBottom>
          Welcome back, <strong>{full_name}</strong>!
        </Typography>
        <Typography variant="body2">Please use the Menu on the left to navigation the system.</Typography>
      </Paper>
    </main>
  );
}

export const Route = createFileRoute('/_authenticated/')({
  component: () => <Dashboard />,
});
