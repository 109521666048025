import * as React from 'react';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import {
  DataGrid,
  DataGridProps,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowSelectionModel,
  GridSlotProps,
  GridSlots,
  GridSortModel,
} from '@mui/x-data-grid';
import { PrimitiveAtom, useSetAtom } from 'jotai';
import { env } from '@/env';
import { SearchState } from '@/hooks/useDataGridData';
import { getAuthorizationHeader } from '@/jotai/account';
import { useOpenSnackbar } from '@/jotai/snackbar';
import { DTOFactory, ParseFields } from '@/lib/dto';
import { FetchError, fetchResponseHandler } from '@/lib/fetch-utils';
import { FlattenKeys } from '@/types/helper';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import { CrudGridRowContextProvider } from './CrudGridRowContext';
import EditToolbar from './EditToolbar';
import FullFeaturedCrudGridPagination, { FullFeaturedCrudGridPaginationProps } from './FullFeaturedCrudGridPagination';

/**
 * @author Oscar
 * @description Wire up the data to Yii2 CRUD API
 */

interface BaseRowData extends Record<string, unknown> {
  id: string | number;
  isNew?: boolean;
}

export interface FullFeaturedCrudGridProps<TRow extends BaseRowData, DK, BK> extends DataGridProps {
  initialRows: TRow[] | undefined;
  refetch: () => void;
  columns: GridColDef[];
  dataAtom: PrimitiveAtom<SearchState>;
  baseUrl: string;
  paginationModelsProps?: {
    totalElements: number;
    pageSize: number;
    page: number;
    pageSizeOptions?: number[];
  };
  dto?: boolean;
  dtoDateFields?: DK[];
  dtoBooleanFields?: BK[];
  dtoDatetimeFields?: DK[];
  insertable?: boolean;
  exportable?: boolean;
  filterable?: boolean;
  deletable?: boolean;
  cellEditable?: boolean;
  checkboxSelection?: boolean;
  confirmDelete?: boolean;
  startActionFC?: React.FC<{ id: string | number }>;
  customSortFieldMapper?: Record<string, string>;
  exportTableKey?: string;
}

const filterOperators = (type: string = 'string') => {
  if (type === 'string') {
    return getGridStringOperators().filter(({ value }) => !['isAnyOf' /* add more over time */].includes(value));
  }
  if (type === 'date') {
    return getGridDateOperators();
  }
  if (type === 'dateTime') {
    return getGridDateOperators(true).filter(({ value }) => !['is', 'not' /* add more over time */].includes(value));
  }
  if (type === 'boolean') {
    return getGridBooleanOperators();
  }
  if (type === 'number') {
    return getGridNumericOperators();
  }
  if (type === 'singleSelect') {
    return getGridSingleSelectOperators();
  }
};

export function FullFeaturedCrudGrid<
  TRow extends BaseRowData,
  DK extends FlattenKeys<TRow>,
  BK extends FlattenKeys<TRow>,
>({
  initialRows,
  refetch,
  columns,
  dataAtom,
  baseUrl,
  paginationModelsProps,
  dto,
  dtoDateFields,
  dtoBooleanFields,
  dtoDatetimeFields,
  insertable = true,
  exportable = true,
  filterable = true,
  deletable = true,
  cellEditable = true,
  checkboxSelection = false,
  confirmDelete = true,
  startActionFC,
  customSortFieldMapper,
  exportTableKey,
  ...props
}: FullFeaturedCrudGridProps<TRow, DK, BK>) {
  const [errorRows, setErrorRows] = React.useState<{ [key: string]: { [key: string]: string }[] }>({});
  const snackbarOpen = useOpenSnackbar();
  const columnsWithModify = columns.map((column) => {
    return {
      ...column,
      filterOperators: filterOperators(column.type),
    };
  });
  // console.log(columnsWithModify);
  const columnsWithAction: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }: { id: TRow['id'] }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const actionComponent = [];
        if (isInEditMode && cellEditable) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: 'primary.main' }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        if (startActionFC) {
          actionComponent.push(React.createElement(startActionFC, { id }));
        }
        if (cellEditable && rowEditingId === null && !rowCreatingId) {
          actionComponent.push(
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          );
        }
        if (deletable && rowEditingId === null && !rowCreatingId) {
          if (confirmDelete) {
            actionComponent.push(<ConfirmDeleteDialog handleDeleteClick={handleDeleteClick(id)} rowId={id} />);
          } else {
            actionComponent.push(
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
              />
            );
          }
        }
        return actionComponent;
      },
    },
    ...columnsWithModify.map((column) => ({ ...column })),
  ];
  const [rows, setRows] = React.useState<Array<TRow>>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [rowEditingId, setRowEditingId] = React.useState<GridRowId | null>(null);
  const setDataAtom = useSetAtom(dataAtom);
  const rowCreatingId = React.useMemo(() => rows.find((row) => row.isNew)?.id ?? null, [rows]);

  const onProcessRowUpdate = async (newRow: TRow) => {
    const { format } = new DTOFactory<TRow>().getDTO(dtoDateFields, dtoBooleanFields, dtoDatetimeFields);
    const data = dto ? format(newRow as ParseFields<TRow, DK, BK>) : newRow;
    if (newRow.isNew) {
      await fetch(`${env.VITE_API_BASE_URL}${baseUrl}`, {
        method: 'POST',
        headers: {
          ...(await getAuthorizationHeader()),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(fetchResponseHandler())
        .then(() => snackbarOpen('Record added successfully.'))
        .then(refetch);
      return { ...newRow, isNew: false };
    } else {
      await fetch(`${env.VITE_API_BASE_URL}${baseUrl}/${newRow.id}`, {
        method: 'PUT',
        headers: {
          ...(await getAuthorizationHeader()),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(fetchResponseHandler())
        .then(() => snackbarOpen('Record updated successfully.'))
        .then(refetch)
        .then(() => setRowEditingId(null));
      return newRow;
    }
  };

  const onProcessRowUpdateErrorHandler = React.useCallback(
    (error: FetchError) => {
      if (error instanceof FetchError) {
        let msg = '';
        if (error.status === 422) {
          const errors = (error.data as []).map((e: { field: string; message: string }) => ({
            [e.field]: e.message,
          }));
          setErrorRows({ [rowCreatingId ?? rowEditingId ?? '']: errors });
          msg = (error.data as []).map((e: { field: string; message: string }) => e.message).join('\n');
        } else if (error.status === 500) {
          msg = error.message;
        }
        snackbarOpen(`Failed to update record. ${msg}`);
      } else {
        snackbarOpen('Failed to update record.');
      }
    },
    [snackbarOpen, rowCreatingId, rowEditingId]
  );
  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    } else {
      setRowEditingId(null);
      setErrorRows({});
    }
  };

  const handleRowEditStart: GridEventListener<'rowEditStart'> = (params) => {
    setRowEditingId(params.id);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowEditingId(id); // set rowEditingId to the id of the row being edited, the rowEditingStart event not trigger by this function
    if (!rowEditingId) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    await fetch(`${env.VITE_API_BASE_URL}${baseUrl}/${id}`, {
      method: 'DELETE',
      headers: {
        ...(await getAuthorizationHeader()),
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          // Throw an error if the response is not ok
          throw new Error("The server couldn't delete the record dur to integrity exception.");
        }
        return response;
      })
      .then(() => setRows(rows.filter((row) => row.id !== id)))
      .then(() => snackbarOpen('Record deleted successfully.'))
      .then(refetch)
      .catch((error) => {
        if (error instanceof FetchError) {
          if (error.status === 422) {
            const errors = (error.data as []).map((e: { field: string; message: string }) => ({
              [e.field]: e.message,
            }));
            setErrorRows({ [rowCreatingId ?? rowEditingId ?? '']: errors });
            const msg = (error.data as []).map((e: { field: string; message: string }) => e.message).join('\n');
            snackbarOpen(`Failed to delete record. ${msg}`);
          } else {
            snackbarOpen(`Error: ${' ' + error ? error.message : 'Failed to delete record.'}`);
          }
        } else {
          snackbarOpen('Failed to delete record.');
        }
      });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowEditingId(null); // set rowEditingId to null, the rowEditingStart event not trigger by this function
    setErrorRows({});
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleFilterChange = React.useCallback(
    async (filterModel: GridFilterModel) => {
      console.log(filterModel);
      // Here you save the data you need from the filter model
      if (filterModel.items.length === 0) {
        setDataAtom((state: SearchState) => ({
          ...state,
          form: {
            ...state.form,
          },
        }));
        return;
      } else {
        const field = filterModel.items[0].field;
        const operator = filterModel.items[0].operator;
        const value = filterModel.items[0].value;
        if (field && operator) {
          const { format } = new DTOFactory<TRow>().getDTO(dtoDateFields, dtoBooleanFields, dtoDatetimeFields);
          const data = dto ? format({ [`${field}`]: value } as ParseFields<TRow, DK, BK>) : value;
          if (operator === 'isAnyOf') {
            setDataAtom((state: SearchState) => ({
              ...state,
              form: {
                ...state.form,
                field: field,
                operator: operator,
                [`value[]`]: data[field],
              },
            }));
          } else {
            setDataAtom((state: SearchState) => ({
              ...state,
              form: {
                ...state.form,
                field: field,
                operator: operator,
                value: data[field],
              },
            }));
          }
        } else {
          setDataAtom((state: SearchState) => ({
            ...state,
            form: {
              ...state.form,
            },
          }));
          return;
        }
      }
    },
    [setDataAtom, dto, dtoDateFields, dtoBooleanFields, dtoDatetimeFields]
  );

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      if (sortModel.length === 0) {
        return;
      }
      if (rowEditingId || rowCreatingId) return alert('Please save or cancel the current edit before sorting');
      const sortField = customSortFieldMapper
        ? customSortFieldMapper[sortModel[0].field] ?? sortModel[0].field
        : sortModel[0]?.field ?? 'id';
      const sortOrder = sortModel[0].sort;
      setDataAtom((state: SearchState) => ({
        ...state,
        sort: {
          key: sortField,
          asc: sortOrder === 'asc',
        },
      }));
    },
    [setDataAtom, customSortFieldMapper, rowCreatingId, rowEditingId]
  );

  const handlePaginationModelChange = React.useCallback(
    (paginationModel: { pageSize: number; page: number }) => {
      setDataAtom((state: SearchState) => ({
        ...state,
        page: paginationModel.page,
        pageSize: paginationModel.pageSize,
      }));
    },
    [setDataAtom]
  );

  const setCellClassName = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: GridCellParams<any, any, number>) => {
      if (errorRows[params.id]) {
        for (const errors of errorRows[params.id]) {
          // Check each field that has an error
          for (const errorField in errors) {
            if (errorField === params.field) {
              if (params.colDef.type == 'date' || params.colDef.type == 'dateTime') {
                return 'error-cell_date_field';
              }
              if (params.colDef.type == 'string') {
                return 'error-cell_string_field';
              }
              if (params.colDef.type == 'boolean') {
                return 'error-cell_boolean_field';
              }
              return 'error-cell'; // Return the class name as soon as a match is found
            }
          }
        }
      }
      return '';
    },
    [errorRows]
  );

  React.useEffect(() => {
    setRows(initialRows ?? []);
  }, [initialRows]);

  return (
    <CrudGridRowContextProvider value={{ rowSelectionModel, setRowSelectionModel }}>
      <Box
        sx={{
          height: rows?.length > 0 ? 'auto' : '500px',
          width: '100%',
          backgroundColor: 'background.paper',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: paginationModelsProps?.pageSize ?? 10 } },
          }}
          rows={rows}
          columns={deletable || cellEditable ? columnsWithAction : columnsWithModify}
          editMode="row"
          isCellEditable={(params) =>
            cellEditable &&
            ((rowCreatingId === null && rowEditingId === null) ||
              params.id === rowEditingId ||
              params.id === rowCreatingId)
          }
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          paginationMode="server"
          rowCount={paginationModelsProps?.totalElements ?? -1}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={paginationModelsProps?.pageSizeOptions ?? [5, 10, 25, 50]}
          processRowUpdate={onProcessRowUpdate}
          onProcessRowUpdateError={onProcessRowUpdateErrorHandler}
          checkboxSelection={checkboxSelection}
          disableRowSelectionOnClick
          onRowSelectionModelChange={setRowSelectionModel}
          rowSelectionModel={rowSelectionModel}
          getCellClassName={setCellClassName}
          slots={{
            toolbar: EditToolbar as GridSlots['toolbar'],
            pagination: FullFeaturedCrudGridPagination as GridSlots['pagination'],
          }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
              insertable,
              exportable,
              filterable,
              rowCreatingId,
              rowEditingId,
              exportTableKey,
            },
            pagination: { rowEditingId, rowCreatingId } as FullFeaturedCrudGridPaginationProps &
              GridSlotProps['pagination'],
          }}
          sx={{
            '& .MuiDataGrid-cell--editing input.MuiInputBase-input:not(.MuiAutocomplete-input)': {
              border: '1px solid rgba(180, 180, 180, 1)',
            },
          }}
          {...props}
        />
      </Box>
    </CrudGridRowContextProvider>
  );
}
