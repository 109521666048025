import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { typographyVariants } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { LoginForm } from '@/routeComponents/login/LoginForm';

function LoginPage() {
  const { redirect } = Route.useSearch();
  return (
    <main className="container mx-auto max-w-lg py-8">
      <h1 className={cn(typographyVariants({ variant: 'h2' }))}>Login</h1>
      <LoginForm redirectTo={redirect} />
    </main>
  );
}

const searchSchema = z.object({ redirect: z.string().trim().default('/') });
export const Route = createFileRoute('/_unauthenticated/login')({
  validateSearch: (search) => searchSchema.parse(search),
  component: LoginPage,
});
