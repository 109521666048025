import { type GridColDef } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { useDataGridData, type SearchState } from '@/hooks/useDataGridData';
import { ActivityCompletionStatusAPI } from '@/types/activityCompletionStatus';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'name', headerName: 'Name', type: 'string', editable: true, width: 200 },
  { field: 'code', headerName: 'Code', type: 'string', editable: true, width: 100 },
  { field: 'seq', headerName: 'Seq', type: 'number', editable: true, width: 60 },
  { field: 'active', headerName: 'Active', type: 'boolean', editable: true, width: 100 },
];

const activityCompletionStatusAtom = atom<SearchState>({
  form: {},
  sort: { key: 'id', asc: true },
  page: 0,
  pageSize: 10,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

function ActivityCompletionStatusView() {
  const {
    result: { refetch, data },
    searchState,
  } = useDataGridData<ActivityCompletionStatusAPI>({
    baseUrl: '/assistant/admin/activity-completion-statuses',
    atom: activityCompletionStatusAtom,
    dto: true,
    dtoBooleanFields: ['active'],
  });
  return (
    <FullFeaturedCrudGrid
      columns={columns}
      initialRows={data?.items}
      refetch={refetch}
      dataAtom={activityCompletionStatusAtom}
      baseUrl="/assistant/admin/activity-completion-statuses"
      dto
      dtoBooleanFields={['active']}
      paginationModelsProps={{
        totalElements: searchState?.totalElements,
        pageSize: searchState?.pageSize,
        page: searchState?.page,
      }}
      deletable={false}
      exportable={false}
    />
  );
}

export { ActivityCompletionStatusView };
