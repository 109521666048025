import { GridColDef, ValueOptions } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { AutocompleteEditInputCell } from '@/components/form/AutocompleteEditInputCell';
import { FullFeaturedCrudGrid } from '@/components/list/FullFeaturedCrudGrid';
import { SearchState, useDataGridData } from '@/hooks/useDataGridData';
import { useValuesData } from '@/hooks/useValuesData';
import { datetimeToPresentFormat, dateToPresentFormat } from '@/lib/utils';
import { LogbookAPI } from '@/types/logbook';
import { RotationValueOption, StudentValueOption } from '@/types/values';

const logbookAtom = atom<SearchState>({
  form: {
    expand: 'rotation',
  },
  sort: { key: 'id', asc: true },
  page: 0,
  pageSize: 10,
  totalElements: 10,
  totalPages: 1,
  numberOfElements: 0,
});

function LogbookView() {
  const {
    result: { refetch, data },
    searchState,
  } = useDataGridData<LogbookAPI>({
    baseUrl: '/assistant/admin/logbooks',
    atom: logbookAtom,
    dto: true,
    dtoDatetimeFields: ['created_at', 'updated_at'],
  });
  const {
    result: { data: valueRotationData },
  } = useValuesData({
    baseUrl: '/assistant/values/rotations',
  });
  const {
    result: { data: valueStudentData },
  } = useValuesData({
    baseUrl: '/assistant/values/students',
  });
  const {
    result: { data: valueLogbookStatusData },
  } = useValuesData({
    baseUrl: '/assistant/values/logbook-statuses',
  });
  const {
    result: { data: valueSpecialtyData },
  } = useValuesData({
    baseUrl: '/assistant/values/specialties ',
  });
  // console.log(valueRotationData);
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'rotation_id',
      headerName: 'Rotation',
      type: 'singleSelect',
      filterable: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.value || '',
      valueGetter: (params) => {
        return params;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => {
        return `${value.label}`;
      },
      valueOptions: valueRotationData.items as ValueOptions[],
      width: 300,
      sortable: false,
      renderEditCell: (params) => {
        if (!params.row.isNew)
          return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (valueRotationData.items.find((item: any) => item.value === params.value) as RotationValueOption)?.label ??
            ''
          );
        return (
          <AutocompleteEditInputCell
            params={params}
            valueOptions={valueRotationData.items as RotationValueOption[]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getOptionLabel={(option: any) => {
              return (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (valueRotationData.items.find((item: any) => item.value === option) as RotationValueOption)?.label ??
                option?.label ??
                ''
              );
            }}
            freeSolo={false}
            multiple={false}
          />
        );
      },
      editable: true,
    },
    {
      field: 'rotation.start_date',
      headerName: 'Rotation Start Date',
      type: 'date',
      editable: false,
      renderCell: (params) =>
        params.row.rotation?.start_date ? dateToPresentFormat(new Date(params.row.rotation?.start_date)) : '',
      width: 150,
    },
    {
      field: 'rotation.end_date',
      headerName: 'Rotation End Date',
      type: 'date',
      editable: false,
      renderCell: (params) =>
        params.row.rotation?.end_date ? dateToPresentFormat(new Date(params.row.rotation?.end_date)) : '',
      width: 150,
    },
    {
      field: 'rotation.specialty_id',
      headerName: 'Specialty',
      type: 'singleSelect',
      valueOptions: valueSpecialtyData.items as ValueOptions[],
      renderCell: (params) => {
        return (
          <div>
            {(
              valueSpecialtyData?.items?.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (item: any) => item.value === params.row?.rotation?.specialty_id
              ) as {
                label: string;
              }
            )?.label || ''}
          </div>
        );
      },
      editable: false,
      width: 150,
    },
    {
      field: 'student_id',
      headerName: 'Student',
      type: 'singleSelect',
      width: 300,
      valueOptions: valueStudentData.items as StudentValueOption[],
      renderEditCell: (params) => {
        if (!params.row.isNew)
          return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (valueStudentData.items.find((item: any) => item.value === params.value) as StudentValueOption)?.label ?? ''
          );
        return (
          <AutocompleteEditInputCell
            params={params}
            valueOptions={valueStudentData.items as StudentValueOption[]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getOptionLabel={(option: any) => {
              return (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (valueStudentData.items.find((item: any) => item.value === option) as StudentValueOption)?.label ??
                option?.label ??
                ''
              );
            }}
            freeSolo={false}
            multiple={false}
          />
        );
      },
      sortable: false,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (value: any) => value?.code || '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (value: any) => value.name,
      valueOptions: valueLogbookStatusData.items as ValueOptions[],
      editable: true,
      width: 150,
    },
    {
      field: 'added_activity',
      headerName: 'Added Activity',
      renderCell: (params) => {
        return <div>{params.row.activitiesCount}</div>;
      },
      width: 150,
    },
    {
      field: 'created_at',
      type: 'dateTime',
      valueFormatter: (params) =>
        params ? datetimeToPresentFormat(new Date(params)) : datetimeToPresentFormat(new Date()),
      headerName: 'Created At',
      width: 200,
    },
    {
      field: 'updated_at',
      type: 'dateTime',
      valueFormatter: (params) =>
        params ? datetimeToPresentFormat(new Date(params)) : datetimeToPresentFormat(new Date()),
      headerName: 'Updated At',
      width: 200,
    },
  ];

  return (
    <FullFeaturedCrudGrid
      columns={columns}
      initialRows={data?.items}
      refetch={refetch}
      dataAtom={logbookAtom}
      baseUrl="/assistant/admin/logbooks"
      dto
      dtoDatetimeFields={['created_at', 'updated_at']}
      paginationModelsProps={{
        totalElements: searchState?.totalElements,
        pageSize: searchState?.pageSize,
        page: searchState?.page,
      }}
      customSortFieldMapper={{ rotation_id: 'rotation.start_date' }}
      exportTableKey={'logbook'}
    />
  );
}

export { LogbookView };
