import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Link, Outlet } from '@tanstack/react-router';
import { LayoutDashboardIcon, LogOutIcon, MenuIcon, Table2Icon, User2Icon } from 'lucide-react';
import { useLogout } from '@/jotai/account';
import { cn } from '@/lib/utils';
import { typographyVariants } from '../ui/typography';

function LeftDrawer() {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  return (
    <React.Fragment>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon className="size-6" />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box className="w-[250px] pt-8" onClick={toggleDrawer(false)}>
          <List className="">
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/" className="flex w-full items-center">
                  <ListItemIcon>
                    <LayoutDashboardIcon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/rotation" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Rotation" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/logbook" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Logbook" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/logbook-activity" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Logbook Activity" />
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem>
              <Typography className={cn(typographyVariants({ variant: 'h4' }))}>Administrator</Typography>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/admin-user" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Admin User" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/hospital" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Hospital" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/specialty" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Specialty" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/procedure" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Procedure" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <Link to="/activity-completion-status" className="flex w-full items-center">
                  <ListItemIcon>
                    <Table2Icon className="mr-2 size-6" aria-hidden />
                  </ListItemIcon>
                  <ListItemText primary="Activity Completion Status" />
                </Link>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

function LoggedInLayout() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = useLogout();
  return (
    <>
      <header className="flex h-16 items-center gap-2 bg-white px-[clamp(0.25rem,1vw,1rem)] text-primary-foreground">
        <LeftDrawer />
        <Link
          to="/"
          className="rounded-md outline-none ring-offset-background hover:underline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
        >
          <img src="/med_logo.png" alt="CUHK Med logo" />
        </Link>
        <div className="flex-1" />
        <div>
          <Button
            id="user-menu-button"
            aria-controls={open ? 'user-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            onClick={handleClick}
          >
            <User2Icon className="size-6" />
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <LogOutIcon className="mr-2 size-4" aria-hidden />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </div>
      </header>
      <Outlet />
      <footer className="bg-slate-700 px-2 pb-4 pt-8 text-primary-foreground">
        <p className="text-center text-sm">© Copyright 2024 by FPO, Faculty of Medicine, CUHK</p>
      </footer>
    </>
  );
}

export { LoggedInLayout };
