import { Button } from '@mui/material';
import { useNavigate, useParams } from '@tanstack/react-router';
import { PasswordElement } from 'react-hook-form-mui';
import { z } from 'zod';
import YiiZodForm from '@/components/form/YiiZodForm';
import { env } from '@/env';
import { useOpenSnackbar } from '@/jotai/snackbar';
import { fetchResponseHandler } from '@/lib/fetch-utils';

const resetPasswordSchema = z.object({
  password: z.string().min(1),
  confirm_password: z.string().min(1),
});

const ResetPasswordForm = () => {
  const snackbarOpen = useOpenSnackbar();
  const navigate = useNavigate();
  const params = useParams({ from: '/_unauthenticated/reset-password/$token' });
  return (
    <YiiZodForm
      zodSchema={resetPasswordSchema}
      onSubmit={async (values) => {
        return fetch(`${env.VITE_API_BASE_URL}/assistant/account/reset-password?token=${params.token}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
        })
          .then(fetchResponseHandler<{ success: boolean }>())
          .then((data) => {
            if (data) {
              snackbarOpen('Password reset successfully. Please login with new password.');
              navigate({ to: '/login', search: { redirect: '/' }, replace: true });
            }
          });
      }}
    >
      <div className="flex flex-col gap-4">
        <PasswordElement name="password" label="password" fullWidth />
        <PasswordElement name="confirm_password" label="Confirm Password" fullWidth />
        <Button variant="contained" type="submit">
          Reset Password
        </Button>
      </div>
    </YiiZodForm>
  );
};

export default ResetPasswordForm;
